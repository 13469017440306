<template>
  <div>
    <!-- UniverseTheGame/images/picB36E638DD02CA3957298F93B57545F53.png -->
    <banner-module
      :img="require('@/assets/the-game/free-to-play-banner.png')"
    ></banner-module>
    <text-module title="What is Free to Play?">
      <div style="margin: 0px 10px 10px">
        <span style="display: block; margin-bottom: 20px"
          ><router-link style="float: right; margin-left: 20px" to="/freetoplay"
            ><!-- UniverseTheGame/images/pic7907A002E7DE4B6C312574CA420C0D2D.png --><img
              src="@/assets/the-game/ftp-ad.png"
            />
          </router-link>
          <p style="width: 430px">
            Build your own LEGO hero and enter the epic battle against evil -
            for FREE!
          </p>
          <p style="width: 430px">
            By creating a Free to Play LEGO Universe account you get to play
            this exciting, award-winning online game for as long as you want.
          </p>
          <p style="width: 430px">
            Simply download the game and start playing online, via our closed
            and secure game browser. It’s that simple!
          </p>
          <p style="width: 430px">
            Create your Free to Play zone account today and find out for
            yourself what the buzz is all about!
          </p>
        </span>
        <span
          ><router-link
            style="float: right; margin-bottom: 50px; margin-left: 20px"
            to="/the-game/getting-started/membership"
            ><!-- UniverseTheGame/images/pic4C2600C30533A166B83C320FB77F884E.png --><img
              src="@/assets/the-game/membership.png"
            />
          </router-link>
          <span
            style="
              display: block;
              font-weight: bold;
              font-size: 16px;
              margin: 20px 0px;
              color: #fff;
            "
            >What can I do in Free to Play?</span
          >
          <span style="display: block; margin-bottom: 20px; margin-left: 30px"
            ><!-- UniverseTheGame/images/picA12D50C60F6292B236348C94F3133233.png --><img
              style="float: left; margin-left: -30px"
              src="@/assets/the-game/star-bullet.png"
            />
            <b>Battle: </b>
            Collect gear and weapons and prepare for an epic battle! Fight the
            mighty Spider Queen and the dangerous Stromlings to save
            Imagination.</span
          >
          <span style="display: block; margin-bottom: 20px; margin-left: 30px"
            ><img
              style="float: left; margin-left: -30px"
              src="@/assets/the-game/star-bullet.png"
            />
            <b>Be the Hero:</b>
            Custom build your own LEGO hero and discover your destiny as you
            join the Nexus Force to save imagination!</span
          >
          <span style="display: block; margin-bottom: 20px; margin-left: 30px"
            ><img
              style="float: left; margin-left: -30px"
              src="@/assets/the-game/star-bullet.png"
            />
            <b>Explore:</b>
            Assemble your rocket ship aboard The Venture Explorer and go on
            missions. Blast off to the incredible world of Avant Gardens and
            discover what the world has to offer as you.</span
          >
          <span style="display: block; margin-bottom: 20px; margin-left: 30px"
            ><img
              style="float: left; margin-left: -30px"
              src="@/assets/the-game/star-bullet.png"
            />
            <b>Build: </b>
            Defeat the mighty Spider Queen, and you’ll be able to unleash your
            creativity on your very own property. Build models and watch them
            come to life with basic behaviors or visit thousands of properties
            built by other players.
          </span>
          <span
            style="
              display: block;
              font-weight: bold;
              font-size: 16px;
              margin: 40px 0px 20px;
              color: #fff;
            "
            >How do I sign up for Free to Play?</span
          >
          You can sign up for Free to Play directly through LEGOuniverse.com.
          Click the "download" button and save LEGO Universe onto your computer.
          Once installed, create a LEGO ID or use an existing LEGO ID to
          activate your Free to Play account and you are ready to play.<span
            style="
              display: block;
              font-weight: bold;
              font-size: 16px;
              margin: 40px 0px 20px;
              color: #fff;
            "
            >Please note:</span
          >
          <i
            >If you are under the age of 13 and create a LEGO ID for Free to
            Play, a parental activation of this account is required within 60
            days. If a parent has not activated the account within 60 days, your
            LEGO ID will be frozen and you will not be able to access the game
            or use your LEGO ID on LEGO.com until the confirmation is
            complete.<br />
            <br />
            Once you upgrade to a membership, you cannot return to Free to Play
            with the same account. If you wish to return to playing in Free to
            Play Zone, you will need to create a new account and character using
            a new LEGO ID.
          </i>
        </span>
      </div>
    </text-module>
  </div>
</template>